import cx from 'classnames'
import { useId } from 'react'
import IntlTelInput, { type CountryData } from 'react-intl-tel-input'
import 'react-intl-tel-input/dist/main.css'

interface TelInputFieldProps {
  errorMessage?: string
  label?: string
  borderBottom?: boolean
  onChange: (value: string) => void
  placeholder?: string
  defaultCountry?: string
  defaultValue?: string
  className?: string
  inputClassName?: string
}

const preferredCountries = ['dk']

const TelInputField = ({
  placeholder,
  label,
  errorMessage,
  borderBottom,
  onChange,
  defaultCountry,
  defaultValue,
  className,
  inputClassName,
}: TelInputFieldProps) => {
  const id = useId()

  const handleChange = (
    isValid: boolean,
    value: string,
    countryData: CountryData,
    fullNumber: string,
    extension: string,
  ) => {
    onChange(fullNumber)
  }

  return (
    <div className={cx('grid', className)}>
      <div className={cx('flex flex-col relative text-left text-sm')}>
        {label && (
          <label htmlFor={id} className="font-medium mb-2">
            {label}
          </label>
        )}
        <div
          className={cx('flex justify-between', {
            'border-error': errorMessage,
            'border-input-border': !errorMessage,
            'mb-[1px] border-b': borderBottom,
            'border rounded': !borderBottom,
          })}
        >
          <IntlTelInput
            format
            fieldId={id}
            placeholder={placeholder}
            defaultCountry={defaultCountry}
            defaultValue={defaultValue}
            preferredCountries={preferredCountries}
            onPhoneNumberChange={handleChange}
            containerClassName="intl-tel-input w-full"
            inputClassName={cx(
              'relative appearance-none w-full h-full py-3 px-4',
              'leading-none',
              'bg-input-bg text-input-text',
              inputClassName,
            )}
          />
        </div>
        {errorMessage && (
          <span role="alert" className="mt-2 font-medium text-xs text-error">
            {errorMessage}
          </span>
        )}
      </div>
    </div>
  )
}

export default TelInputField
